<template>
    <div>
      <!-- 三级路由的占位符 -->
      <router-view />
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="less" scoped></style>
  